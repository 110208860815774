<template>
  <!--  each is a greyed out circle but purple if currentstep is greater then their index, active step is a rectable of 55px width-->
  <div class="flex items-center justify-center space-x-4">
    <template v-for="step in steps" :key="step">
      <div
        class="rounded-full bg-send-grey-80"
        :class="[stepClass, activeStepClass(step), completedStepClass(step)]"
      ></div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";

type Sizes = "lg" | "md" | "sm";

const props = defineProps({
  totalSteps: {
    type: Number,
    required: true,
    min: 2,
  },
  currentStep: {
    type: Number,
    required: true,
    min: 1,
  },
  size: {
    type: String as () => Sizes,
    default: "md",
  },
});

const steps = Array.from({ length: props.totalSteps }, (_, i) => i + 1);
const stepClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "w-2 h-2";
    case "md":
      return "w-3 h-3";
    case "lg":
      return "w-4 h-4";
    default:
      return "";
  }
});
const isCurrentStep = (step: number) => step === props.currentStep;
const activeStepClass = (step: number) => {
  return isCurrentStep(step)
    ? "!w-14 ![background:linear-gradient(90deg,#7B5AFF_0%,#B09CFF_100%)]"
    : "";
};
const completedStepClass = (step: number) => {
  return step < props.currentStep
    ? "![background:linear-gradient(90deg,#7B5AFF_0%,#B09CFF_100%)]"
    : "bg-send-grey-80";
};
</script>

<style scoped></style>
